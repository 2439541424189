import { useEffect, useState} from 'react';
import './App.css';
import { Card } from 'flowbite-react';

function App() {

  const [data, setData] = useState(null);

  useEffect(() => {
    fetch('https://iac-app.tecrolabs.dev/users/')
    .then(response => response.json())
    .then(data => setData(data))
    .catch(error => console.error(error));  
   }, []);
  
  return (
    <Card className="m-10 bw-full max-w-md p-10 bg-grey border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 ">
        <div className="flex items-center justify-between">
        <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">Latest Users</h5>
      </div>
      <div className="flow-root">
        <ul className="divide-y divide-gray-200 dark:divide-gray-700">

    {data &&
          data.map(({ id, username, email, bio, image }) => (
    
    <div key={id}>
        
      <li className="py-3 sm:py-4">
            <div className="flex items-center space-x-4">
              <div className="shrink-0">
              <img className="rounded-full w-16 h-16" src={image} alt=''/>
              </div>
              <div className="min-w-0 flex-1">
                <p className="truncate text-md font-medium text-gray-900 dark:text-white">{username}</p>
                <p className="truncate text-sm text-gray-700 dark:text-white">{email}</p>
                <p className="text-sm text-gray-600 dark:text-gray-400 italic">{bio}</p>
              </div>
            </div>
          </li>

      </div>
      ))}

     </ul>
      </div>
    </Card>     

  );

  }
export default App;
