import { Footer } from 'flowbite-react';

export default function FooterMenu() {
  return (
    <Footer container>
      <div className="w-full text-center">
        <div className="w-full justify-between sm:flex sm:items-center sm:justify-between">
          <Footer.Brand
            href="#"
            src="https://loremflickr.com/32/32/logo/"
            alt="Flowbite Logo"
            name="Random Company"
          />
          <Footer.LinkGroup>
            <Footer.Link href="#">Privacy Policy</Footer.Link>
          </Footer.LinkGroup>
        </div>
        <Footer.Divider />
        <Footer.Copyright href="#" by="Random Company | Version 0.0.1" year={2023}/>
      </div>
    </Footer>
  );
}
