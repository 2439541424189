import { Breadcrumb } from 'flowbite-react';
import { HiHome } from 'react-icons/hi';
import { HiUser } from 'react-icons/hi';

export default function  BreadcrumbMenu() {
  return (
    <Breadcrumb className='m-10' aria-label="Default breadcrumb example">
      <Breadcrumb.Item href="#" icon={HiHome}>
        Home
      </Breadcrumb.Item>
      <Breadcrumb.Item href="#" icon={HiUser}> 
      Users
      </Breadcrumb.Item>
      
    </Breadcrumb>
  );
}